<template>
  <v-card
    :class="minimum ? 'pl-1' : 'pa-0'" 
    tile
    flat
    :loading="loading"
    @dblclick="allowEdit(document.documenttypeid.name) ? editDocumentItemTranslation() : null"
  >
    <div v-if="documentItemTranslation" class="d-flex flex-column">
      <div class="d-flex">
        <div>
          <ImageManager
            v-if="isRenderComponent('enableTranslationImage')"
            :id="documentItemTranslation.id"
            :connection-type-id="connectionType.documentItemTranslations"
            class="pb-2"
          />
          <div v-if="documentItem && renderTranslationField(documentItem.documentitemtypeid, 'title')" dense>
            <div v-if="showHelperText" small class="overline font-weight-thin">Title</div>
            <span :class="indicLanguage(documentItemTranslation.languageid)" class="font-weight-bold">{{ documentItemTranslation.title }}</span>
          </div>

          <div v-if="documentItem && renderTranslationField(documentItem.documentitemtypeid, 'description')" dense>
            <div v-if="showHelperText" small class="overline font-weight-thin">Description</div>
            <div :class="indicLanguage(documentItemTranslation.languageid)" class="secondary--text" v-html="documentItemTranslation.description"></div>
          </div>

          <div v-if="documentItem && renderTranslationField(documentItem.documentitemtypeid, 'purport')" dense>
            <div v-if="showHelperText" small class="overline font-weight-thin">Purport</div>
            <div :class="indicLanguage(documentItemTranslation.languageid)" class="secondary--text" v-html="documentItemTranslation.purport"></div>
          </div>

          <div v-if="(documentItem && renderTranslationField(documentItem.documentitemtypeid, 'body')) || minimum" dense>
            <div v-if="showHelperText" small class="overline font-weight-thin">Body</div>
            <div 
              v-if="!$store.state.app.helperEnable || documentItemTranslation.languageid === 1" 
              :id="`body-${documentItemTranslation.id}`" 
              :class="indicLanguage(documentItemTranslation.languageid)" 
              v-html="documentItemTranslation.body"
            >
            </div>
          
            <div v-if="$store.state.app.helperEnable && documentItemTranslation.languageid !== 1">

              <div v-if="$store.state.app.helperTransliteration === 'latin'" class="d-flex align-content-start flex-wrap">
                <div 
                  v-if="$store.state.app.helperLayout === 'under'" 
                  v-for="(wordMapping, index) of wordMapper(documentItemTranslation.body, documentItemTranslation.bodylipi)" 
                  :key="index" 
                  :class="indicLanguage(documentItemTranslation.languageid)" 
                  class="pr-2 pb-1"
                >
                  <div v-html="wordMapping.src"></div>
                  <div v-html="wordMapping.lipi"></div>
                </div>
                
                <div 
                  v-if="$store.state.app.helperLayout === 'side'"  
                  v-for="(wordMapping, index) of wordMapper(documentItemTranslation.body, documentItemTranslation.bodylipi)" 
                  :key="index" 
                  :class="indicLanguage(documentItemTranslation.languageid)" 
                  class="pr-1"
                >
                  <div v-html="wordMapping.src+' '+wordMapping.lipi"></div>
                </div>
              </div>

              <div v-if="$store.state.app.helperTransliteration === 'phonetic'" class="d-flex align-content-start flex-wrap">
                <div 
                  v-if="$store.state.app.helperLayout === 'under'" 
                  v-for="(wordMapping, index) of wordMapper(documentItemTranslation.body, documentItemTranslation.bodyphonetic)" 
                  :key="index" 
                  :class="indicLanguage(documentItemTranslation.languageid)" 
                  class="pr-2 pb-1"
                >
                  <div v-html="wordMapping.src"></div>
                  <div v-html="wordMapping.lipi"></div>
                </div>

                <div 
                  v-if="$store.state.app.helperLayout === 'side'"  
                  v-for="(wordMapping, index) of wordMapper(documentItemTranslation.body, documentItemTranslation.bodyphonetic)" 
                  :key="index" 
                  :class="indicLanguage(documentItemTranslation.languageid)" 
                  class="pr-1"
                >
                  <div v-html="wordMapping.src+' '+wordMapping.lipi"></div>
                </div>
              </div>

            </div>
          </div>
          <div v-else>
            <div v-html="documentItemTranslation.body"></div>
          </div>
          <div v-if="showUrl" class="caption">
            <router-link
              v-if="documentItem.breadcrumb"
              :to="documentItemUrl"
            >
              {{ documentBreadcrumbTitle }}
            </router-link>
          </div>
        </div>

        <v-spacer></v-spacer>
        <DocumentItemTranslationMenu v-if="allowEdit(document.documenttypeid.name)" :document-item-translation="documentItemTranslation" />
      </div>
    </div>
    <v-card-actions small class="ma-0 pa-0" v-if="!minimum">
      <v-btn color="blue lighten-2" text @click="showReferences = !showReferences" small>
        <v-icon size="10">mdi-asterisk</v-icon>  References
      </v-btn>
    </v-card-actions>

    <v-expand-transition>
      <div v-if="showReferences">
        <Connections 
          :source-id="documentItemTranslation.id" 
          :connection-type-id="connectionType.documentItemTranslations" 
          :show-no-items-message="true"
          :language-id="documentItemTranslation.languageid"
        />
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import configs from '@/configs'
import ImageManager from '@/components/ImageManager'
import DocumentItemTranslationMenu from '@/components/documentItemTranslations/DocumentItemTranslationMenu'
import Connections from '@/components/connections/Connections'
import helperUtils from '@/helpers/utils'
import { map } from 'jquery'

export default {
  name: 'DocumentItemTranslation',
  components: {
    ImageManager,
    DocumentItemTranslationMenu,
    Connections
  },
  props: {
    index: {
      type: Number,
      default: null
    },
    translationId: {
      type: [Number,String],
      default: null
    },
    minimum: {
      type: Boolean,
      default: false
    },
    showUrl: {
      type: Boolean,
      default: false
    },
    group: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    connectionType: configs.connectionProperties.types,
    cardActionsVisible: {},
    showReferences: false,
    loading: true
  }),
  computed: {
    ...mapState('documents', ['document']),
    ...mapGetters('documents', [
      'renderComponent',
      'renderTranslationField'
    ]),
    ...mapGetters('documentItems', [
      'getDocumentItemTranslationsById',
      'getDocumentItemsById'
    ]),
    ...mapGetters('account', ['allowEdit']),
    showHelperText() {
      return this.documentItem.parentdocumentitemid === null
    },
    documentItemTranslation() {
      return this.getDocumentItemTranslationsById(this.translationId)
    },
    documentItem() {
      if (!this.documentItemTranslation) { return null }

      return this.getDocumentItemsById(this.documentItemTranslation.documentitemid)
    },
    documentItemBreadcrumbTitle() {
      return helperUtils.generateTitleFromBreadcrumb(
        this.connectionType.documentItems, 
        this.documentItem.breadcrumb, 
        null
      )
    },
    documentItemUrl() {
      return helperUtils.generateUrlFromBreadcrumb(
        null, 
        this.documentItems.documentid,
        this.documentItem.breadcrumb, 
        this.connectionType.documentItems
      )
    }
  },
  watch: {},
  created() {
    this.loadData()
  },
  methods: {
    indicLanguage(languageid) {
      if (languageid === 1) {
        return 'text-body-2'
      }

      return 'hari-text'
    },
    wordMapper: function (src, lipi) {
      const regex = /(<([^>]+)>)/ig
      const words = []
      
      if (!src) { return words }

      src = src.replace(regex, '').split(' ')
      lipi = lipi.replace(regex, '').split(' ')

      if (!src && src.length === 0) { return words }

      src.forEach((e, i) => {
        const wordObject = {
          src: src[i] ? src[i] : null,
          lipi: lipi[i] ? lipi[i] : null,
          order: i
        }

        words.push(wordObject)
      })

      return words
    },
    editDocumentItemTranslation() {
      console.log(`editDocumentItemTranslation languageid=${this.documentItemTranslation.languageid}`)
      this.$router.push({ 
        name: 'documentitem_translations_edit', 
        params: {
          documentitemid: this.documentItemTranslation.documentitemid,
          documentitemtranslationid: String(this.documentItemTranslation.id)
        } 
      })
    },
    showCardActionsShow (translation) {
      Vue.set(this.cardActionsVisible, documentItemTranslation.id, true)
    },
    showCardActionsHide (translation) {
      Vue.set(this.cardActionsVisible, documentItemTranslation.id, false)
    },
    isRenderComponent(componentName) {
      if (!this.documentItem) { return false }

      return this.renderComponent(this.documentItem.documentitemtypeid, componentName)
    },
    async loadData() {
      this.loading = true
      if (!this.documentItemTranslation) {
        await this.$store.dispatch('documentItems/getDocumentItemTranslation', this.translationId)
      }
      this.loading = false
    }
  }
}

</script>

<style>
referencelink,
reference,
person,
phrase,
definition {
  /* font-weight: bold; */
  cursor: pointer;
}
person {
  background-color: rgb(218, 255, 163);
}
phrase {
  background-color: rgb(255, 255, 179);
}
definition {
  background-color: rgb(255, 177, 255);
}
reference {
  display: block;
  padding: 0px 10px;
  border-left: 7px darkred double;
  border-right: 7px darkred double;
}
referencelink {
  text-decoration-color: darkgoldenrod;
  text-decoration-line: underline;
  text-decoration-style: wavy;
}
highlight {
  color: chocolate;
  font-weight: bolder !important;
}
#create .v-speed-dial { position: absolute; }
#create .v-btn--floating { position: relative; }
</style>